import { Box, Tab, Tabs } from "@mui/material";

function TabPanel({ value, index, ...props }) {
  return (
    <Box
      className="center-flex no-scroll"
      sx={{
        minWidth: value === index ? "100%" : "0%",
        height: "100%",
        overflow: "auto",
      }}
    >
      {value === index && props.children}
    </Box>
  );
}

function TabsDisplay({ value, setValue, tabs, width = 262, height = 240 }) {
  return (
    <Box
      sx={{
        border: "2px solid #424242",
        width: width,
      }}
    >
      <Box minHeight={72} sx={{ borderBottom: 1, borderColor: "gray" }}>
        <Tabs
          value={value}
          onChange={(e, n) => {
            setValue(n);
          }}
          textColor="primary"
          indicatorColor="primary"
          centered
        >
          {tabs.map((t, i) => (
            <Tab
              sx={{
                color: "white",
                maxWidth: 80,
                minWidth: 80,
                fontSize: 12.5,
                gap: 0.5,
                "&:disabled": {
                  color: "#424242",
                },
              }}
              icon={t.icon}
              label={t.label}
              disabled={t.disabled}
              key={i}
              disableRipple
              disableFocusRipple
              disableTouchRipple
            />
          ))}
        </Tabs>
      </Box>
      <Box
        className="center-flex"
        sx={{
          position: "relative",
          width: "100%",
          height: height,
        }}
      >
        {tabs.map((t, i) => (
          <TabPanel value={value} index={i} key={i}>
            {t.panel}
          </TabPanel>
        ))}
      </Box>
    </Box>
  );
}

export default TabsDisplay;
