import { Box, Stack, Typography, useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import JJButton from "../components/JJButton";
import JJTextField from "../components/JJTextField";
import { authenticate, instance } from "../helpers/BackendUtils";

function Register({ setTitle }) {
  useEffect(() => {
    setTitle("Register");
  }, []);

  const navigate = useNavigate();
  const [canLoad, setCanLoad] = useState(false);
  const isMobile = useMediaQuery("(max-width: 660px)");

  useEffect(() => {
    authenticate().then((res) => {
      if (res.length !== 0) {
        navigate("/");
      } else {
        setCanLoad(true);
      }
    });
  }, []);

  const [inputs, setInputs] = useState({
    username: "",
    password: "",
    confirmPassword: "",
  });
  const [errors, setErrors] = useState({
    username: "",
    password: "",
    confirmPassword: "",
  });

  const fields = [
    ["username", "Username", "text", true],
    ["password", "Password", "password", true],
    ["confirmPassword", "Confirm Password", "password", true],
  ];

  const handleSubmit = () => {
    const errs = structuredClone(errors);
    const username = inputs.username;
    const password = inputs.password;
    const confirmPassword = inputs.confirmPassword;
    // Check that the passwords fields match.
    if (password !== confirmPassword) {
      const error = "Password fields must match";
      errs.password = error;
      errs.confirmPassword = error;
    }
    // Username Conditions
    if (
      username.length < 3 ||
      username.length > 24 ||
      !username.match(/^[a-zA-Z0-9]+$/)
    ) {
      errs.username =
        "Username must be 3-24 characters long and contain only alphanumeric characters";
    }
    // Password Conditions
    if (
      password.length < 8 ||
      password.length > 24 ||
      !password.match(/^.*([a-zA-Z].*[0-9]|[0-9].*[a-zA-Z]).*$/)
    ) {
      const error =
        "Password must be 8-24 characters long and contain at least one letter and one number";
      errs.password = error;
      errs.confirmPassword = error;
    }
    // Check that all required fields are non-empty.
    fields.forEach((field, idx) => {
      if (field[3] && inputs[field[0]].length === 0) {
        errs[field[0]] = `${field[1]} field cannot be empty`;
      }
    });
    // If there are no errors, send to backend.
    if (Object.values(errs).every((e) => e.length === 0)) {
      instance
        .post("/api/register", {
          username: inputs.username,
          password: inputs.password,
        })
        .then((res) => {
          const data = res.data;
          if (data.type === "success") {
            navigate("/login");
          }
        })
        .catch((err) => {
          const errs2 = structuredClone(errors);
          const data = err?.response?.data;
          for (const s of data.fields) {
            errs2[s] = data.error;
          }
          setErrors(errs2);
        });
    } else {
      setInputs((inputs) => ({
        ...inputs,
        password: "",
        confirmPassword: "",
      }));
    }
    setErrors(errs);
  };

  return (
    <>
      {canLoad ? (
        <Box className="center-hor" width="100%" marginTop={8}>
          <Stack
            className="item-container center-hor"
            width={isMobile ? 320 : 400}
            boxSizing="border-box"
            padding={3}
            gap={2}
          >
            <Typography
              textAlign="center"
              fontWeight="bold"
              fontSize={24}
              margin={2}
            >
              Create an Account
            </Typography>
            {fields.map((f, idx) => (
              <JJTextField
                id={f[0]}
                label={f[1]}
                type={f[2]}
                required={f[3]}
                value={inputs[f[0]]}
                onChange={(e) => {
                  const errs = structuredClone(errors);
                  const i = structuredClone(inputs);
                  i[f[0]] = e.target.value;
                  setInputs(i);
                  Object.keys(errs).forEach((k) => (errs[k] = ""));
                  // errs[f[0]] = "";
                  setErrors(errs);
                }}
                error={errors[f[0]].length > 0}
                helperText={errors[f[0]]}
                key={idx}
              />
            ))}
            <JJButton
              variant="contained"
              width={200}
              bgcolor={(theme) => theme.palette.primary.main}
              borderColor={(theme) => theme.palette.primary.main}
              borderRadius={1}
              onClick={handleSubmit}
            >
              Register
            </JJButton>
            <Stack direction="row" gap={1}>
              <Typography fontSize={12}>Already have an account? </Typography>
              <Link to="/login" style={{ textDecoration: "none" }}>
                <Typography
                  fontSize={12}
                  fontWeight="bold"
                  color="deepskyblue"
                  sx={{
                    ":hover": {
                      cursor: "pointer",
                    },
                  }}
                >
                  Login
                </Typography>
              </Link>
            </Stack>
          </Stack>
        </Box>
      ) : (
        <></>
      )}
    </>
  );
}

export default Register;
