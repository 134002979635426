import { Box, Stack, keyframes, useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";
import JJDialog from "../components/JJDialog";
import { instance } from "../helpers/BackendUtils";
import { niceNumber, timeDisplay } from "../helpers/Utils";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import JJPopover from "../components/JJPopover";

const imgAnim = (trans) => keyframes`
0% {
  transform: translate(-48px, ${trans}px) scaleX(-1);
}
100% {
  transform: translate(-48px, ${trans + 10}px) scaleX(-1);
}
`;

const borderAnim = (color, from, to) => keyframes`
0% {
  box-shadow: 0 0 ${from} ${color};
}
100% {
  box-shadow: 0 0 ${to} ${color};
}
`;

const leaderboards = [
  {
    name: "wordle",
    display: "Wordle",
    types: [
      {
        path: "wins",
        display: "Wins",
      },
    ],
  },
  {
    name: "24",
    display: "24",
    types: [
      {
        path: "max/score",
        display: "Best Score",
      },
      {
        path: "sum/score",
        display: "Total Score",
      },
    ],
  },
  {
    name: "nanopix",
    display: "NanoPix",
    types: [
      {
        path: "min/time",
        display: "Best Times",
        process: (x) => {
          x.value = timeDisplay(x.value, "15px");
        },
      },
      {
        path: "wins",
        display: "Wins",
      },
    ],
  },
  {
    name: "2048",
    display: "2048",
    types: [
      {
        path: "max/highestTile",
        display: "Best Tile",
        process: (x) => {
          x.value = Math.pow(2, x.value);
        },
      },
      {
        path: "max/score",
        display: "Best Score",
      },
      {
        path: "wins",
        display: "Wins",
      },
    ],
  },
  {
    name: "pokedle",
    display: "Pokédle",
    types: [
      {
        path: "pokedexComplete",
        display: "Pokédex Complete",
        process: (x) => {
          x.value = `${((x.value * 100) / 2020).toFixed(2)}%`;
        },
      },
      {
        path: "wins",
        display: "Pokémon Caught",
      },
      {
        path: "shinies",
        display: "Shinies",
      },
    ],
  },
];

function LeaderboardDisplay({ leaderboard = [] }) {
  const isMobile = useMediaQuery("(max-width: 660px)");
  const width = isMobile ? 280 : 360;
  const createBG = (c1, c2) => `linear-gradient(${c1} 25%, ${c2} 75%)`;

  return (
    <Box
      className="item-container no-scroll"
      width={width}
      height="380px"
      borderColor="peru"
    >
      <Stack width="100%" margin={1} gap={1}>
        {leaderboard.map((x, idx) => {
          let colors = ["#424242", "#424242", "#424242"];
          if (x.rank === 1) {
            colors = ["rgb(238,174,56)", "rgb(181,122,15)", "rgb(217,147,19)"];
          } else if (x.rank === 2) {
            colors = [
              "rgb(167,167,167)",
              "rgb(128,128,128)",
              "rgb(148,148,148)",
            ];
          } else if (x.rank === 3) {
            colors = ["rgb(225,112,31)", "rgb(173,86,23)", "rgb(225,112,30)"];
          }
          return (
            <Stack
              fontSize={15}
              color={x.rank < 4 ? "ghostwhite" : "silver"}
              key={idx}
              padding={1}
              boxSizing="border-box"
              direction="row"
              borderRadius={1}
              border={`2px solid ${colors[0]}`}
              sx={{
                backgroundImage: createBG(colors[1], colors[2]),
                animation:
                  x.rank < 4
                    ? `${borderAnim(
                        colors[0],
                        "8px",
                        "2px"
                      )} 1.25s infinite alternate`
                    : undefined,
              }}
              gap={2}
            >
              <Box flex={1} textAlign="center">
                {x.rank}
              </Box>
              <Box flex={8}>{x.username}</Box>
              <Box flex={2} textAlign="center">
                {x.value}
              </Box>
            </Stack>
          );
        })}
      </Stack>
    </Box>
  );
}

function TypeButton({ onClick, isSelected, ...props }) {
  return (
    <Box
      onClick={onClick}
      width="100%"
      textAlign="center"
      fontSize={11}
      bgcolor={isSelected ? "darkgoldenrod" : "#424242"}
      color={isSelected ? "white" : "silver"}
      padding={1}
      boxSizing="border-box"
      borderRadius={1}
      sx={{
        ":hover": {
          cursor: "pointer",
        },
      }}
    >
      {props.children}
    </Box>
  );
}

// Drop down for mobile only.
function DropDown({ display, ...props }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  return (
    <>
      <Box position="relative" width="100%">
        <ArrowDropDownIcon sx={{ position: "absolute", height: "100%" }} />
        <TypeButton
          onClick={(e) => setAnchorEl(e.currentTarget)}
          isSelected={true}
        >
          {display}
        </TypeButton>
      </Box>
      <JJPopover
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        width={150}
        height={220}
        sx={{ borderColor: "goldenrod" }}
      >
        <Box className="no-scroll" width="100%" height="100%">
          <Stack gap={1} onClick={() => setAnchorEl(null)}>
            {props.children}
          </Stack>
        </Box>
      </JJPopover>
    </>
  );
}

function LeaderboardDialog({ open, setOpen, start, ...props }) {
  const isMobile = useMediaQuery("(max-width: 660px)");

  const [type, setType] = useState(0);
  const [subtype, setSubtype] = useState(0);
  const [leaderboard, setLeaderboard] = useState([]);

  useEffect(() => {
    if (!open) return;
    const startIdx =
      start == null ? 0 : leaderboards.findIndex((x) => x.name === start);
    setType(startIdx);
    setSubtype(0);
  }, [open, start]);

  useEffect(() => {
    instance
      .get(
        `/api/leaderboard/${leaderboards[type].name}/${leaderboards[type].types[subtype].path}`
      )
      .then((res) => {
        const lb = res?.data;
        const forEach = leaderboards[type].types[subtype].process;
        lb.forEach(
          forEach == null
            ? (x) => {
                x.value = niceNumber(x.value);
              }
            : forEach
        );
        setLeaderboard(lb || []);
      });
  }, [open, start, type, subtype]);

  const typeList = leaderboards.map((x, idx) => (
    <TypeButton
      isSelected={type === idx}
      onClick={() => {
        setType(idx);
        setSubtype(0);
      }}
      key={idx}
    >
      {x.display}
    </TypeButton>
  ));

  const subtypeList = leaderboards[type].types.map((x, idx) => (
    <TypeButton
      isSelected={subtype === idx}
      onClick={() => setSubtype(idx)}
      key={idx}
    >
      {x.display}
    </TypeButton>
  ));

  return (
    <>
      <JJDialog
        open={open}
        setOpen={setOpen}
        title="Leaderboards"
        titleColor="lemonchiffon"
        sx={{
          animation: `${borderAnim(
            "goldenrod",
            "8px",
            "18px"
          )} 1.25s infinite alternate`,
          borderColor: "darkorange",
        }}
      >
        {isMobile ? (
          <>
            <Stack gap={2}>
              <Stack direction="row" justifyContent="space-between" gap={2}>
                <DropDown display={leaderboards[type].display}>
                  {typeList}
                </DropDown>
                <DropDown display={leaderboards[type].types[subtype].display}>
                  {subtypeList}
                </DropDown>
              </Stack>
              <LeaderboardDisplay leaderboard={leaderboard} />
            </Stack>
          </>
        ) : (
          <Stack direction="row" gap={2}>
            <Stack gap={2}>
              <Box
                className="item-container no-scroll"
                width="150px"
                flex={3}
                borderColor="goldenrod"
              >
                <Stack width="100%" margin="8px" gap={1}>
                  {typeList}
                </Stack>
              </Box>
              <Box
                className="item-container no-scroll"
                width="150px"
                flex={2}
                borderColor="goldenrod"
              >
                <Stack width="100%" margin={1} gap={1}>
                  {subtypeList}
                </Stack>
              </Box>
            </Stack>
            <LeaderboardDisplay leaderboard={leaderboard} />
          </Stack>
        )}
      </JJDialog>
    </>
  );
}

export default LeaderboardDialog;
