import { Alert, Fade, Snackbar } from "@mui/material";
import { useEffect, useState } from "react";

function JJSnackbar({ content }) {
  const [snackPack, setSnackPack] = useState([]);
  const [open, setOpen] = useState(false);
  const [snackContent, setSnackContent] = useState(undefined);

  useEffect(() => {
    if (content !== undefined) {
      setSnackPack((prev) => [...prev, content]);
    }
  }, [content]);

  useEffect(() => {
    if (snackPack.length) {
      if (snackContent === undefined) {
        const m = snackPack[0];
        setSnackContent(m);
        setSnackPack((prev) => prev.slice(1));
        setOpen(true);
      } else if (snackContent !== undefined && open) {
        setOpen(false);
        setSnackContent(undefined);
      }
    }
  }, [snackPack, snackContent, open]);

  const message = snackContent ? snackContent.message : undefined;
  const type = snackContent ? snackContent.type : undefined;

  return (
    <Snackbar
      sx={{ minWidth: "100px" }}
      open={open}
      onClose={(e, r) => {
        if (r === "clickaway") return;
        setOpen(false);
        setSnackContent(undefined);
      }}
      autoHideDuration={1000}
      message={message}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      TransitionComponent={Fade}
      ContentProps={{
        sx: {
          display: "block",
          textAlign: "center",
        },
      }}
    >
      <Alert
        icon={false}
        severity={type}
        sx={{
          minWidth: "150px",
          display: "block",
          textAlign: "center",
        }}
      >
        {message}
      </Alert>
    </Snackbar>
  );
}

export default JJSnackbar;
