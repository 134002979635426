import CloseIcon from "@mui/icons-material/Close";
import { Box, Dialog, IconButton, Stack } from "@mui/material";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { ReactElement, Ref, forwardRef } from "react";

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: ReactElement<any, any>,
  },
  ref: Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function JJDialog({
  open,
  setOpen,
  title,
  titleColor = "white",
  canHighlight = false,
  extra,
  ...props
}) {
  const handleClose = (e, reason) => {
    if (reason && reason === "backdropClick") {
      setOpen(false);
    }
  };
  return (
    <Dialog
      className={canHighlight ? "" : "no-highlight"}
      open={open}
      fullWidth={true}
      maxWidth={"sm"}
      onClose={handleClose}
      TransitionComponent={Transition}
      PaperProps={{
        sx: {
          backgroundColor: "#1f1f1f",
          border: "2px solid gray",
          borderRadius: "20px",
          color: "white",
          padding: "24px",
          overflow: "visible",
          ...props.sx,
        },
      }}
    >
      {extra == null ? (
        <></>
      ) : (
        <Box className="center-flex" position="absolute" sx={{ zIndex: -1 }}>
          {extra}
        </Box>
      )}
      <IconButton
        aria-label="close"
        onClick={() => {
          setOpen(false);
        }}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <Box sx={{ justifyContent: "space-evenly" }} className="center-vert fill">
        <Stack
          sx={{ height: "10%", fontSize: "30px", fontWeight: "bold" }}
          gap={2}
          className="center-vert"
        >
          {title == null ? undefined : <Box color={titleColor}>{title}</Box>}
          {props.children}
        </Stack>
      </Box>
    </Dialog>
  );
}

export default JJDialog;
