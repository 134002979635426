import EditIcon from "@mui/icons-material/Edit";
import InfoIcon from "@mui/icons-material/Info";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import {
  Box,
  Stack,
  Tooltip,
  Typography,
  keyframes,
  useMediaQuery,
} from "@mui/material";
import { useEffect, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import JJButton from "../components/JJButton";
import JJDialog from "../components/JJDialog";
import JJSnackbar from "../components/JJSnackbar";
import JJTextField from "../components/JJTextField";
import TabsDisplay from "../components/TabsDisplay";
import { authenticate, instance } from "../helpers/BackendUtils";

const imgAnim = (trans) => keyframes`
0% {
  transform: translate(-48px, ${trans}px) scaleX(-1);
}
100% {
  transform: translate(-48px, ${trans + 10}px) scaleX(-1);
}
`;
const borderAnim = keyframes`
0% {
  box-shadow: 0 0 8px gold;
}
100% {
  box-shadow: 0 0 24px deepskyblue;
}
`;

const discordPng = <img width="32px" src="discord.png" alt="Discord" />;
const discord = "N/A";
const divider = <Box height="1px" width="200px" bgcolor="gray" />;

function TextSection({ ...props }) {
  return (
    <Typography
      textAlign="center"
      width="90%"
      fontSize={12.5}
      color="silver"
      sx={{ ...props.sx }}
    >
      {props.children}
    </Typography>
  );
}

function FeedbackForm({
  isMobile,
  user,
  errMsg,
  setOpen,
  setContentSB,
  ...props
}) {
  const color = (theme) => theme.palette.primary.main;
  const minChars = 50;
  const maxChars = 500;

  const [content, setContent] = useState("");
  const [rating, setRating] = useState(0);

  const s = isMobile ? 20 : 24;

  return (
    <Stack className="center-flex" width="100%" gap={1}>
      <Box position="relative" width="100%">
        {errMsg != null ? (
          <Box
            className="center-flex"
            position="absolute"
            zIndex={1}
            fontSize={12}
            color="silver"
          >
            <Box width="75%">{errMsg}</Box>
          </Box>
        ) : undefined}
        <JJTextField
          width="100%"
          multiline
          rows={isMobile ? 8 : 5}
          placeholder={`${minChars} to ${maxChars} characters...`}
          fontSize="12px"
          padding="8px"
          value={content}
          background="#171717"
          onChange={(e) => {
            const text = e.target.value;
            if (text.length <= maxChars) {
              setContent(e.target.value);
            }
          }}
          disabled={errMsg != null}
        />
      </Box>
      <Stack
        width="100%"
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Stack className="center-vert" height="100%" direction="row" gap={0.5}>
          {Array.from(Array(5).keys()).map((i, idx) => (
            <Box
              className="center-flex"
              key={idx}
              onClick={() => {
                if (errMsg == null) {
                  const newValue = idx + 1;
                  setRating(rating === newValue ? 0 : newValue);
                }
              }}
              sx={{
                scale: "120%",
                ":hover":
                  errMsg != null
                    ? undefined
                    : {
                        cursor: "pointer",
                      },
              }}
            >
              {idx < rating ? (
                <StarIcon sx={{ color: "goldenrod", fontSize: s }} />
              ) : (
                <StarBorderIcon sx={{ color: "gray", fontSize: s }} />
              )}
            </Box>
          ))}
        </Stack>
        <JJButton
          width={100}
          height={isMobile ? 35 : 40}
          bgcolor={color}
          borderColor={color}
          borderRadius={1}
          disabled={
            user?.length === 0 ||
            content.length < minChars ||
            content.length > maxChars
          }
          onClick={() => {
            instance
              .post("/api/feedback", {
                feedback_type: "",
                category: "",
                rating: rating,
                content: content,
              })
              .then((res) => {
                const tm = res.data.tm;
                if (tm != null) {
                  // Success
                  setContent("");
                  setRating(0);
                  setOpen(false);
                  setContentSB({
                    message: "Feedback Sent!",
                    type: "success",
                  });
                }
              });
          }}
        >
          Submit
        </JJButton>
      </Stack>
    </Stack>
  );
}

function AboutDialog({ open, setOpen, ...props }) {
  const isMobile = useMediaQuery("(max-width: 660px)");
  const imgSize = isMobile ? 200 : 260;
  const trans = isMobile ? -250 : -280;

  const [value, setValue] = useState(0);
  const [contentSB, setContentSB] = useState(undefined);
  const [feedbackErr, setFeedbackErr] = useState("Please Wait...");
  const [updateLog, setUpdateLog] = useState(undefined);
  const [user, setUser] = useState(undefined);

  useEffect(() => {
    setValue(0);
    if (open) {
      // Feedback
      instance
        .get("/api/feedback-check")
        .then((res) =>
          setFeedbackErr(
            res?.data?.type === "error" ? res?.data?.message : undefined
          )
        );
      // Updates
      instance.get("/api/updates").then((res) => setUpdateLog(res.data));
      // User
      authenticate().then((res) => {
        setUser(res);
      });
    }
  }, [open]);

  const infoPanel = () => {
    return (
      <Box height="100%" width="100%">
        <Stack className="center-hor" padding="10px 0px" gap={1.25}>
          <Typography fontWeight="bold" fontSize={18} margin={0.5}>
            About This Site
          </Typography>
          {divider}
          <Stack className="center-flex" gap={1} margin="5px 0px">
            <TextSection>
              This website is a personal project developed by yours truly -
              JJosha! It is home to a bunch of singleplayer and multiplayer
              mini-games.
            </TextSection>
            <TextSection>
              This is still in the early stages of development, and I will
              continue to work on new features and updates constantly. I hope
              you enjoy!
            </TextSection>
          </Stack>
          {divider}
          <Stack gap={1}>
            <CopyToClipboard
              text={discord}
              onCopy={() =>
                setContentSB({
                  message: "Copied to Clipboard",
                  type: "primary",
                })
              }
            >
              <Tooltip title={discord} placement="top">
                <Box className="center-flex hover">{discordPng}</Box>
              </Tooltip>
            </CopyToClipboard>
            <TextSection sx={{ fontWeight: "bold" }}>Discord</TextSection>
          </Stack>
        </Stack>
      </Box>
    );
  };

  const feedbackPanel = () => {
    return (
      <Box
        className="center-hor no-scroll"
        maxHeight="100%"
        alignItems="flex-start"
      >
        <Stack
          className="center-hor"
          boxSizing="border-box"
          textAlign="center"
          height="100%"
          width="90%"
          padding="8px 0px"
          fontSize={18}
          gap={1}
        >
          <Typography fontSize={18} fontWeight="bold" margin={0.5}>
            Feedback Form
          </Typography>
          {divider}
          <Typography width="100%" fontSize={12} color="silver" padding={1}>
            If you have any feedback, suggestions or bugs you'd like to report,
            please submit them here! (Rating is optional)
          </Typography>
          <FeedbackForm
            user={user}
            isMobile={isMobile}
            errMsg={feedbackErr}
            setOpen={setOpen}
            setContentSB={setContentSB}
          />
        </Stack>
      </Box>
    );
  };

  const updatesPanel = () => {
    return (
      <Box className="no-scroll" width="100%" height="100%">
        {updateLog == null || updateLog.length === 0 ? (
          <Box className="center-flex" color="silver" fontSize={18}>
            Nothing to show...
          </Box>
        ) : (
          <Stack
            className="no-scroll center-hor"
            gap={1.25}
            margin={1}
            boxSizing="border-box"
          >
            {updateLog.map((x, idx) => (
              <Box
                className="item-container"
                boxSizing="border-box"
                padding={1.5}
                borderRadius={3}
                width="100%"
                key={idx}
              >
                <Stack position="relative" gap={1.5} width="100%">
                  <Stack textAlign="center" mt="18px">
                    <Typography fontSize={18} fontWeight="bold">
                      {x.title}
                    </Typography>
                    <Typography fontSize={12} fontWeight="bold">
                      by {x.username}
                    </Typography>
                  </Stack>
                  <Box className="center-hor" width="100%">
                    {divider}
                  </Box>
                  <Typography
                    position="absolute"
                    color="silver"
                    fontSize={12}
                    top={0}
                    right={0}
                  >
                    {x.tm.slice(0, 10)}
                  </Typography>
                  <Typography
                    color="silver"
                    fontSize={12}
                    margin="16px 0px"
                    sx={{
                      whiteSpace: "pre-line",
                    }}
                  >
                    {x.content}
                  </Typography>
                </Stack>
              </Box>
            ))}
          </Stack>
        )}
      </Box>
    );
  };

  const tabs = [
    {
      icon: <InfoIcon />,
      label: "Info",
      panel: infoPanel(),
    },
    {
      icon: <EditIcon />,
      label: "Feedback",
      panel: feedbackPanel(),
    },
    {
      icon: <MenuBookIcon />,
      label: "Updates",
      panel: updatesPanel(),
    },
  ];

  return (
    <>
      <JJSnackbar content={contentSB} />
      <JJDialog
        open={open}
        setOpen={setOpen}
        extra={
          <Box
            sx={{
              animation: `${imgAnim(trans)} 1.25s infinite alternate`,
            }}
          >
            <img
              width={imgSize}
              height={imgSize}
              src="oshawott.png"
              alt="Oshawott"
            />
          </Box>
        }
        sx={{
          paddingTop: "20px",
          paddingBottom: "10px",
          animation: `${borderAnim} 1.25s infinite alternate`,
        }}
      >
        <Box margin="20px 0px">
          <TabsDisplay
            value={value}
            setValue={setValue}
            tabs={tabs}
            width={isMobile ? 250 : 360}
            height={isMobile ? 240 : 280}
          />
        </Box>
      </JJDialog>
    </>
  );
}

export default AboutDialog;
