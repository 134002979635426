import Send from "@mui/icons-material/Send";
import { Box, Button, Stack, TextField } from "@mui/material";
import { useState } from "react";
import JJDialog from "../components/JJDialog";

function SettingsMenu({ open, setOpen, changeTheme }) {
  const [code, setCode] = useState("");

  const content = (
    <Box>
      <Box fontSize="16px">
        <Stack>
          <Box width="100%" margin="8px 0px">
            Secret Code:
          </Box>
          <Stack flexDirection="row">
            <TextField
              sx={{
                border: "2px solid #424242",
                background: "#171717",
                borderRadius: "8px",
                input: {
                  color: "white",
                  fontWeight: "bold",
                  textAlign: "center",
                  fontSize: "16px",
                  padding: 1.5,
                  width: "160px",
                },
              }}
              onChange={(e) => {
                setCode(e.target.value.substring(0, 12));
              }}
              value={code}
            />
            <Button
              variant="filled"
              onClick={() => {
                changeTheme(code);
                setCode("");
              }}
            >
              <Send />
            </Button>
          </Stack>
        </Stack>
      </Box>
    </Box>
  );

  return (
    <JJDialog open={open} setOpen={setOpen} title="Settings">
      {content}
    </JJDialog>
  );
}

export default SettingsMenu;
