import {
  Box,
  Button,
  keyframes,
  Stack,
  Typography,
  CircularProgress,
  useMediaQuery,
} from "@mui/material";
import Person2OutlinedIcon from "@mui/icons-material/Person2Outlined";
import Person2Icon from "@mui/icons-material/Person2";
import { useEffect, useState } from "react";

function QueueDisplay({
  gameName = "None",
  curPlayers = 1,
  maxPlayers = 2,
  leaveQueue,
}) {
  const anim = keyframes`
  0% {
    box-shadow: 0 0 12px white
  }
  100% {
    box-shadow: 0 0 24px white
  }
  `;

  const isMobile = useMediaQuery("(max-width: 660px)");
  const [i, setI] = useState(0);

  useEffect(() => {
    const intervalID = setInterval(() => {
      setI(i + 1);
    }, 1000);
    return () => clearInterval(intervalID);
  });

  return (
    <Box
      className="no-highlight"
      display="flex"
      position="fixed"
      width="100%"
      justifyContent="center"
      zIndex={1}
      bottom={24}
      visibility={gameName === "None" ? "hidden" : "visible"}
    >
      <Stack
        className="item-container center-hor"
        width={isMobile ? 340 : 600}
        height="120px"
        gap={1}
        boxSizing="border-box"
        padding="12px"
        sx={{
          border: "3px solid white",
          animation: `${anim} 0.5s infinite alternate`,
        }}
      >
        <Stack alignItems="center">
          <Typography fontWeight="bold" fontSize="18px">
            In Queue
          </Typography>
          <Typography fontSize="13px">{gameName}</Typography>
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-evenly"
          width="100%"
        >
          <Stack width="40%" direction="row" gap={1} alignItems="center">
            <CircularProgress size={20} thickness={8} />
            <Typography fontSize="15px" fontWeight="bold">
              {`Waiting${".".repeat((i % 3) + 1)}`}
            </Typography>
          </Stack>
          <Button
            variant="contained"
            sx={{
              minHeight: "80%",
              maxHeight: "80%",
              minWidth: "30%",
              maxWidth: "30%",
            }}
            onClick={leaveQueue}
          >
            Cancel
          </Button>
          <Stack width="40%" textAlign="right" gap={0.2}>
            <Stack direction="row" justifyContent="flex-end">
              {Array.from(Array(Math.min(maxPlayers, 5)).keys()).map((i) =>
                i < curPlayers ? (
                  <Person2Icon fontSize="small" key={i} />
                ) : (
                  <Person2OutlinedIcon
                    fontSize="small"
                    key={i}
                    sx={{ color: "gray" }}
                  />
                )
              )}
            </Stack>
            <Box
              fontWeight="bold"
              fontSize="15px"
            >{`${curPlayers}/${maxPlayers} Players`}</Box>
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
}

export default QueueDisplay;
