import { Box, Popover } from "@mui/material";

function JJPopover({
  width = 240,
  height = 240,
  open,
  anchorEl,
  onClose,
  anchorOrigin,
  transformOrigin,
  sx,
  ...props
}) {
  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
    >
      <Box
        sx={{
          backgroundColor: "#171717",
          border: "2px solid gray",
          color: "white",
          height: height,
          width: width,
          padding: "10px",
          boxSizing: "border-box",
          ...sx,
        }}
      >
        {props.children}
      </Box>
    </Popover>
  );
}

export default JJPopover;
