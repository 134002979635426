import axios from "axios";

const getUrl = () =>
  process.env.NODE_ENV === "production"
    ? `https://${window.location.hostname}`
    : `http://${window.location.hostname}:8080`;

const instance = axios.create({
  withCredentials: true,
  baseURL: getUrl(),
});

const authenticate = () => {
  return instance.get("/api/user").then((res) => res.data);
};

const authenticateThen = (success = () => {}, fail = () => {}) => {
  return authenticate().then((res) => {
    if (res.length !== 0) {
      success();
    } else {
      fail();
    }
  });
};

const openStatsFetch = (name, shouldFetch, setStats) => {
  if (!shouldFetch) return;
  return authenticate()
    .then((res) => {
      if (res.length !== 0) {
        return instance.get(`/api/stats/${name}`);
      }
    })
    .then((res) => {
      if (res != null) {
        setStats(res.data);
      }
      return res?.data;
    })
    .catch((err) => {});
};

const latestMatchFetch = (name, todo) => {
  return authenticate()
    .then((res) => {
      if (res.length !== 0) {
        return instance.get(`/api/latest-match/${name}`);
      }
    })
    .then((res) => todo(res?.data?.latestMatch));
};

export {
  getUrl,
  instance,
  authenticate,
  authenticateThen,
  openStatsFetch,
  latestMatchFetch,
};
