import { Badge, Button, Tooltip } from "@mui/material";

function JJButton({
  variant = "outlined",
  color = "white",
  bgcolor = "gray",
  width = "40px",
  height = "40px",
  onClick,
  disabled,
  border = "2px solid #424242",
  borderColor,
  borderRadius = 2.5,
  sx,
  tTitle,
  tPlacement = "bottom",
  badgeContent = undefined,
  badgeColor = undefined,
  component,
  animate,
  transition,
  hover = {
    background: "#424242",
    border: "2px solid magenta",
    scale: "110%",
    zIndex: 1,
  },
  ...props
}) {
  const button = (
    <Button
      sx={{
        color: color,
        bgcolor: bgcolor,
        width: width,
        minWidth: width,
        maxWidth: width,
        height: height,
        minHeight: height,
        maxHeight: height,
        border: border,
        borderColor: borderColor,
        borderRadius: borderRadius,
        boxSizing: "border-box",
        ":hover": Object.keys(hover).length === 0 ? {} : hover,
        ...sx,
      }}
      onClick={onClick}
      disabled={disabled}
      component={component}
      animate={animate}
      transition={transition}
      disableRipple
      disableTouchRipple
      {...props}
    >
      <Badge badgeContent={badgeContent} color={badgeColor}>
        {props.children}
      </Badge>
    </Button>
  );
  return disabled ? (
    button
  ) : (
    <Tooltip
      title={tTitle}
      placement={tPlacement}
      PopperProps={{
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [0, -8],
            },
          },
        ],
      }}
    >
      {button}
    </Tooltip>
  );
}

export default JJButton;
