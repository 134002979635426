import { Box, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import JJButton from "../components/JJButton";

const niceNumber = (n) => (n || 0).toLocaleString("en-US");

const timeFromMillis = (time) => {
  const iso = new Date(time === undefined ? 0 : time).toISOString();
  const mins = iso.substr(11, 8);
  const millis = iso.substr(19, 4);
  return [mins, millis];
};

const timeDisplay = (
  timeElapsed,
  fontSize = "18px",
  color = "white",
  msMult = 0.6
) => {
  const t = timeFromMillis(timeElapsed);
  return (
    <Stack
      flexDirection="row"
      fontWeight="bold"
      color={color}
      gap="2px"
      alignItems="baseline"
      justifyContent="center"
    >
      <div style={{ fontSize: fontSize }}>{t[0]}</div>
      <div style={{ fontSize: `calc(${msMult} * ${fontSize})` }}>{t[1]}</div>
    </Stack>
  );
};

const statDisplay = (
  label,
  value,
  statWidth = "48px",
  contentFontSize = "16px"
) => {
  return (
    <Stack
      width={statWidth}
      textAlign="center"
      justifyContent="space-between"
      height="45px"
    >
      <Typography
        sx={{ lineHeight: 1.5, flex: 3 }}
        fontSize={contentFontSize}
        fontWeight="bold"
      >
        {value}
      </Typography>
      <Box className="center-flex" height="20px" sx={{ flex: 2 }}>
        <Typography sx={{ lineHeight: 1 }} fontSize="10px">
          {label}
        </Typography>
      </Box>
    </Stack>
  );
};

const textShadow = (color, size = 1.25) => {
  return `-${size}px -${size}px 0 ${color}, ${size}px -${size}px 0 ${color}, -${size}px ${size}px 0 ${color}, ${size}px ${size}px 0 ${color}`;
};

const romanize = (num) => {
  var roman = {
    M: 1000,
    CM: 900,
    D: 500,
    CD: 400,
    C: 100,
    XC: 90,
    L: 50,
    XL: 40,
    X: 10,
    IX: 9,
    V: 5,
    IV: 4,
    I: 1,
  };
  let str = "";
  for (var i of Object.keys(roman)) {
    var q = Math.floor(num / roman[i]);
    num -= q * roman[i];
    str += i.repeat(q);
  }
  return str;
};

const shuffle = (a) => {
  let array = structuredClone(a);
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

const allEqual = (a) => a.every((v, i, a) => v === a[0]);

const statsTabWrapper = (stats, tab) => {
  function Button({ display, to }) {
    const navigate = useNavigate();
    return (
      <JJButton
        width={80}
        variant="contained"
        bgcolor={(theme) => theme.palette["secondary"].main}
        borderColor={(theme) => theme.palette["secondary"].main}
        borderRadius={1}
        onClick={() => navigate(to)}
        sx={{
          fontWeight: "bold",
          fontSize: 12,
        }}
      >
        {display}
      </JJButton>
    );
  }
  return (
    <>
      {stats == null ? (
        <Stack className="center-flex" textAlign="center" gap={2}>
          <Typography fontSize="15px" fontWeight="bold" color="silver">
            Log in or Register to track your stats and progress!
          </Typography>
          <Stack direction="row" gap={1}>
            <Button display="Login" to="/login" />
            <Button display="Register" to="/register" />
          </Stack>
        </Stack>
      ) : (
        <>{tab()}</>
      )}
    </>
  );
};

const setupJoinLeaveFunc = (onJoin, onLeave) => () => {
  onJoin();
  window.addEventListener("beforeunload", onLeave);
  return () => {
    onLeave();
    window.removeEventListener("beforeunload", onLeave);
  };
};

export {
  niceNumber,
  statDisplay,
  timeFromMillis,
  timeDisplay,
  textShadow,
  romanize,
  shuffle,
  allEqual,
  statsTabWrapper,
  setupJoinLeaveFunc,
};
