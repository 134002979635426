import Person2Icon from "@mui/icons-material/Person2";
import { Box, Stack, useMediaQuery, Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import JJButton from "../components/JJButton";
import JJPopover from "../components/JJPopover";
import { authenticate, instance } from "../helpers/BackendUtils";
import SettingsMenu from "./SettingsMenu";
import SettingsIcon from "@mui/icons-material/Settings";

function NavButton({ color = "secondary", onClick, ...props }) {
  return (
    <JJButton
      width={80}
      variant="contained"
      bgcolor={(theme) => theme.palette[color].main}
      borderColor={(theme) => theme.palette[color].main}
      borderRadius={1}
      onClick={onClick}
      sx={{
        fontWeight: "bold",
        fontSize: 12,
      }}
    >
      {props.children}
    </JJButton>
  );
}

function NavMenu({ userDisplay, menuItems, ...props }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  return (
    <>
      <Box onClick={(e) => setAnchorEl(e.currentTarget)}>{props.children}</Box>
      <JJPopover
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        width={240}
        height="100%"
      >
        <Stack className="no-highlight center-hor" gap={1}>
          <Stack className="center-hor">
            <Box fontSize={12} fontWeight="bold" color="silver">
              Account:
            </Box>
            {userDisplay}
          </Stack>
          <Stack width="100%">
            {menuItems.map((category, idx) => (
              <Stack className="center-hor" key={idx}>
                <Box height="2px" width="90%" bgcolor="gray" />
                <Stack width="90%" margin={1} gap={0.5}>
                  {category.map((item, idx2) => (
                    <Box
                      fontWeight="bold"
                      onClick={() => {
                        setAnchorEl(null);
                        item[1]();
                      }}
                      fontSize="13px"
                      width="100%"
                      boxSizing="border-box"
                      color={item.length < 3 ? "white" : item[2]}
                      borderRadius={1}
                      sx={{
                        padding: 0.5,
                        ":hover": {
                          bgcolor: "gray",
                          cursor: "pointer",
                        },
                      }}
                      key={idx2}
                    >
                      {item[0]}
                    </Box>
                  ))}
                </Stack>
              </Stack>
            ))}
          </Stack>
        </Stack>
      </JJPopover>
    </>
  );
}

function NavBar({ title, theme, changeTheme, setOpenAbout }) {
  const isMobile = useMediaQuery("(max-width: 660px)");
  const miniView = useMediaQuery("(max-width: 1000px)");
  const imgSize = isMobile ? 36 : 50;

  const [openSettings, setOpenSettings] = useState(false);
  const [auth, setAuth] = useState(undefined);
  const [reload, setReload] = useState(false);

  const navigate = useNavigate();

  const authFunc = () => {
    authenticate().then((res) => {
      if (auth != null && auth.toString() === res.toString()) return;
      setAuth(res.length !== 0 ? res : undefined);
    });
  };

  const logout = () => {
    instance.post("/api/logout").then((res) => {
      if (res.data.type === "success") {
        navigate("/");
        setReload((prev) => !prev);
      }
    });
  };

  useEffect(authFunc, [title, reload, auth]);

  const menuItems = [[["About", () => setOpenAbout(true)]]];
  const toPush = [];
  if (auth?.roles?.includes("owner")) {
    toPush.push(["Admin", () => navigate("/admin"), "gold"]);
  }
  toPush.push(
    ...(auth
      ? [["Logout", () => logout(), "salmon"]]
      : [
          ["Login", () => navigate("/login")],
          ["Register", () => navigate("/register")],
        ])
  );
  menuItems.push(toPush);

  return (
    <Box width="100%">
      <SettingsMenu
        open={openSettings}
        setOpen={setOpenSettings}
        changeTheme={changeTheme}
      />
      <nav>
        <div
          className="navbar"
          style={
            theme?.banner || {
              backgroundImage:
                "linear-gradient(rgba(16, 16, 16, 0.8) 25%, rgba(16, 16, 16, 1) 75%)",
            }
          }
        >
          <Box className="nav-title no-highlight">
            <Tooltip title="Settings" placement="right">
              <SettingsIcon
                sx={{
                  position: "absolute",
                  right: "8px",
                  color: "gray",
                  "&:hover": { cursor: "pointer", color: "white" },
                }}
                onClick={() => {
                  setOpenSettings(true);
                }}
              />
            </Tooltip>
            {title}
          </Box>
          <Stack
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            height="100%"
          >
            <Box
              className="no-highlight"
              sx={{
                margin: "18px",
                width: imgSize,
                height: imgSize,
                "&:hover": {
                  scale: "110%",
                },
              }}
            >
              <Link to="/">
                <img
                  src={require("../jj-icon.png")}
                  alt="jjosha Logo"
                  width="100%"
                  height="100%"
                  onDragStart={(e) => e.preventDefault()}
                  onContextMenu={(e) => e.preventDefault()}
                />
              </Link>
            </Box>
            <Stack
              alignItems="center"
              direction="row"
              style={{ margin: "18px" }}
              gap={2}
            >
              <Stack
                direction="row"
                gap={1}
                visibility={miniView ? "hidden" : "visible"}
              >
                {auth ? (
                  <NavButton onClick={logout}>Logout</NavButton>
                ) : (
                  <>
                    <Link to="/login" style={{ textDecoration: "none" }}>
                      <NavButton color="secondary">Login</NavButton>
                    </Link>
                    <Link to="/register" style={{ textDecoration: "none" }}>
                      <NavButton color="secondary">Register</NavButton>
                    </Link>
                  </>
                )}
              </Stack>
              <NavMenu
                userDisplay={
                  <Box
                    color={auth ? "white" : "silver"}
                    fontWeight="bold"
                    padding={0.5}
                  >
                    {auth ? auth.username : "Guest User"}
                  </Box>
                }
                menuItems={menuItems}
              >
                <Box
                  className="center-flex hover"
                  height={42}
                  width={42}
                  border="2px solid gray"
                  bgcolor="#424242"
                  borderRadius={10}
                >
                  <Person2Icon
                    sx={{
                      fontSize: 30,
                      color: auth == null ? "gray" : "white",
                    }}
                  />
                </Box>
              </NavMenu>
            </Stack>
          </Stack>
        </div>
      </nav>
    </Box>
  );
}

export default NavBar;
