/* eslint-disable react-hooks/exhaustive-deps */
import { Stack } from "@mui/material";
import { lazy, Suspense, useEffect, useState } from "react";
import {
  Route,
  BrowserRouter as Router,
  Routes,
  useNavigate,
} from "react-router-dom";
import { io } from "socket.io-client";
import useSound from "use-sound";
import "./App.css";
import { instance } from "./helpers/BackendUtils";
import AboutDialog from "./other/AboutDialog";
import NavBar from "./other/NavBar";
import QueueDisplay from "./other/QueueDisplay";
import Login from "./pages/Login";
import Register from "./pages/Register";
import LeaderboardDialog from "./other/LeaderboardDialog";

// Code Splitting...
const G2048 = lazy(() => import("./pages/G2048"));
const Home = lazy(() => import("./pages/Home"));
const Honeycomb = lazy(() => import("./pages/Honeycomb"));
const NanoPix = lazy(() => import("./pages/NanoPix"));
const NotFound = lazy(() => import("./pages/NotFound"));
const TestPage = lazy(() => import("./pages/TestPage"));
const TicTacTwice = lazy(() => import("./pages/TicTacTwice"));
const TwentyFour = lazy(() => import("./pages/TwentyFour"));
const Wordle = lazy(() => import("./pages/Wordle"));
const Pokedle = lazy(() => import("./pages/Pokedle"));
const Overthrow = lazy(() => import("./pages/Overthrow"));
const PlateUpPractice = lazy(() => import("./pages/PlateUpPractice"));
const AdminPage = lazy(() => import("./pages/AdminPage"));

const createDefault = (background, banner, bannerSize = 80) => {
  return {
    background: {
      backgroundImage: background,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundPositionY: "25%",
      backgroundPositionX: "25%",
    },
    banner: {
      backgroundImage: banner,
      backgroundRepeat: "repeat",
      backgroundSize: bannerSize,
      backgroundPositionY: "25%",
    },
  };
};

// Websocket. TODO: Handle connection failure
const socket = io.connect(
  process.env.NODE_ENV === "production" ? "/" : ":8080"
);

function JJoshaRouter() {
  const [title, setTitle] = useState("");
  const [theme, setTheme] = useState({});
  const [queued, setQueued] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    socket.on("queue-update", (curr) => {
      setQueued((queued) => ({
        ...queued,
        curPlayers: curr,
      }));
    });
    socket.on("join-game", (game) => {
      setQueued({});
      navigate(`/${game}`);
    });
  }, [socket]);

  // Load audios - find another solution to this?
  const sounds = {
    pop_add: useSound("pop_add.mp3", {}),
    pop_del: useSound("pop_del.mp3"),
    pop0: useSound("pop0.mp3"),
    pop1: useSound("pop1.mp3"),
    pop2: useSound("pop2.mp3"),
    pop3: useSound("pop3.mp3"),
    pop4: useSound("pop4.mp3"),
    pop5: useSound("pop5.mp3"),
    pop6: useSound("pop6.mp3"),
    pop7: useSound("pop7.mp3"),
    pling: useSound("pling.mp3"),
    orb: useSound("orb.mp3"),
    oof: useSound("oof.mp3"),
    levelup: useSound("levelup.mp3"),
    bass: useSound("bass.mp3"),
    hat: useSound("hat.mp3"),
  };

  const changeTheme = (s) => {
    instance
      .post("/api/themes", {
        theme: s,
      })
      .then((r) => {
        const d = r?.data;
        setTheme(
          d == null ? undefined : createDefault(d?.background, d?.banner)
        );
        localStorage.setItem("theme", s);
      });
  };

  const playSound = (a) => {
    sounds[a][0]();
  };

  useEffect(() => {
    changeTheme(localStorage.getItem("theme") || "default");
  }, []);

  const [openAbout, setOpenAbout] = useState(false);
  const [openLB, setOpenLB] = useState(false);

  return (
    <>
      <AboutDialog open={openAbout} setOpen={setOpenAbout} />
      <LeaderboardDialog open={openLB} setOpen={setOpenLB} />
      <Stack className="page" style={theme?.background} alignItems="center">
        <NavBar
          title={title}
          theme={theme}
          changeTheme={changeTheme}
          setOpenAbout={setOpenAbout}
        />
        <Suspense>
          <Routes>
            <Route
              exact
              path="/"
              element={
                <Home
                  setTitle={setTitle}
                  socket={socket}
                  isQueued={queued.shortName !== undefined}
                  setQueued={setQueued}
                  setOpenAbout={setOpenAbout}
                  setOpenLB={setOpenLB}
                />
              }
            />
            <Route
              exact
              path="/login"
              element={<Login setTitle={setTitle} />}
            />
            <Route
              exact
              path="/register"
              element={<Register setTitle={setTitle} />}
            />
            <Route
              exact
              path="/wordle"
              element={<Wordle setTitle={setTitle} playSound={playSound} />}
            />
            <Route
              exact
              path="/24"
              element={<TwentyFour setTitle={setTitle} playSound={playSound} />}
            />
            <Route
              exact
              path="/tic-tac-twice"
              element={
                <TicTacTwice
                  setTitle={setTitle}
                  playSound={playSound}
                  socket={socket}
                />
              }
            />
            <Route
              exact
              path="/nanopix"
              element={<NanoPix setTitle={setTitle} playSound={playSound} />}
            />
            <Route
              exact
              path="/2048"
              element={<G2048 setTitle={setTitle} playSound={playSound} />}
            />
            <Route
              exact
              path="/honeycomb"
              element={
                <Honeycomb
                  setTitle={setTitle}
                  playSound={playSound}
                  socket={socket}
                />
              }
            />
            <Route
              exact
              path="/pokedle"
              element={<Pokedle setTitle={setTitle} playSound={playSound} />}
            />
            <Route
              exact
              path="/overthrow"
              element={
                <Overthrow
                  setTitle={setTitle}
                  playSound={playSound}
                  socket={socket}
                />
              }
            />
            <Route
              exact
              path="/plateup"
              element={
                <PlateUpPractice setTitle={setTitle} playSound={playSound} />
              }
            />
            <Route
              exact
              path="/admin"
              element={<AdminPage setTitle={setTitle} />}
            />
            <Route path="*" element={<NotFound setTitle={setTitle} />} />
            <Route
              exact
              path="/test"
              element={<TestPage setTitle={setTitle} playSound={playSound} />}
            />
          </Routes>
        </Suspense>
        {/* <Box
          className="item-container center-hor"
          minHeight={"100px"}
          width={isMobile ? "90%" : 620}
          mt="50px"
        >
          <JJAdComponent />
        </Box> */}
      </Stack>
      <QueueDisplay
        gameName={queued.displayName}
        curPlayers={queued.curPlayers}
        maxPlayers={queued.maxPlayers}
        leaveQueue={() => {
          socket.emit("queue-leave", queued.shortName, () => setQueued({}));
        }}
      />
    </>
  );
}

function App(props) {
  return (
    <div className="App">
      <Router>
        <JJoshaRouter />
      </Router>
    </div>
  );
}

export default App;
