import { Box, TextField } from "@mui/material";
import { useState } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

function JJTextField({
  width = "100%",
  height = "auto",
  fontSize = "auto",
  padding = "auto",
  background = "#424242",
  type = "text",
  hasPasswordVisibilityToggle = true,
  ...props
}) {
  const [pwVisible, setPwVisible] = useState(false);

  return (
    <Box
      className="center-vert"
      position="relative"
      width={width}
      height={height}
    >
      <TextField
        InputProps={{
          style: {
            color: "white",
            background: background,
            width: width,
            height: height,
            fontSize: fontSize,
            padding: padding,
          },
        }}
        InputLabelProps={{
          style: {
            color: "silver",
          },
        }}
        type={pwVisible ? "text" : type}
        sx={{
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: "gray",
            },
            "&.Mui-focused fieldset": {
              borderColor: "deepskyblue",
            },
            "&:hover fieldset": {
              borderColor: "silver",
            },
            input: {
              "&:-webkit-autofill": {
                WebkitBoxShadow: "0 0 0 100px #5f6c7a inset",
                WebkitTextFillColor: "#fff",
              },
            },
          },
          ".MuiFormHelperText-root": {
            margin: 0,
            width: width,
          },
        }}
        fullWidth
        {...props}
      />
      {type === "password" && hasPasswordVisibilityToggle ? (
        <Box position="absolute" right={0} top={0} padding={2} color="silver">
          <Box className="hover" onClick={() => setPwVisible((prev) => !prev)}>
            {pwVisible ? <VisibilityIcon /> : <VisibilityOffIcon />}
          </Box>
        </Box>
      ) : undefined}
    </Box>
  );
}

export default JJTextField;
